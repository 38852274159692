<template>
  <div class="container--tmp">
    <p class="content__text--tmp is-red is-center">
      本実証実験は<br />6月23日（日）をもって<br />終了しております
      <!-- 調整中 -->
    </p>
  </div>
</template>

<script>
export default {
  name: "TmpText",
};
</script>
