import Vue from "vue";
import VueRouter from "vue-router";
// import Index from "@/pages/Index";
// import Signage1 from "@/pages/signage/Signage1";
// import Signage2 from "@/pages/signage/Signage2";
// import Admin from "@/pages/Admin";
// import ChangeStatus from "@/pages/admin/ChangeStatus";
// import Forecast from "@/pages/Forecast";
import Text from "@/pages/Text";

Vue.use(VueRouter);

const publicPath = process.env.VUE_APP_PUBLIC_PATH;

/**
 *
 */
const routes = [
  // {
  //   path: publicPath + "tokyo/",
  //   name: "index-tab",
  //   component: Index,
  // },
  {
    // path: publicPath + "",
    path: publicPath + "tokyo/",
    // redirect: publicPath + "tokyo/",
    name: "Text",
    component: Text,
    meta: {
      layout: "detail",
    },
    // name: "index",
    // component: Index,
  },
  // {
  //   path: publicPath + "tokyo/admin",
  //   name: "Admin",
  //   component: Admin,
  //   meta: {
  //     layout: "admin",
  //   },
  // },
  // {
  //   path: publicPath + "tokyo/forecast/",
  //   name: "Forecast",
  //   component: Forecast,
  // },
  // {
  //   path: publicPath + "tokyo/i/:page",
  //   name: "ChangeStatus",
  //   component: ChangeStatus,
  //   meta: {
  //     layout: "admin",
  //   },
  // },
  // {
  //   path: publicPath + "tokyo/:page/",
  //   name: "index-tab",
  //   component: Index,
  // },
  // // 3画面切り替え 飲食店等
  // {
  //   path: publicPath + "tokyo/s/shops/",
  //   name: "Signage1",
  //   component: Signage1,
  //   meta: {
  //     layout: "detail",
  //   },
  // },
  // {
  //   path: publicPath + "tokyo/s/shops/:id",
  //   name: "Signage1Current",
  //   component: Signage1,
  //   meta: {
  //     layout: "detail",
  //   },
  // },
  // // 2画面切り替え 馬券発売所
  // {
  //   path: publicPath + "tokyo/s/ticket-paddock/",
  //   name: "Signage2",
  //   component: Signage2,
  //   meta: {
  //     layout: "detail",
  //   },
  // },
  // {
  //   path: publicPath + "tokyo/s/ticket-paddock/:id",
  //   name: "Signage2Current",
  //   component: Signage2,
  //   meta: {
  //     layout: "detail",
  //   },
  // },
  // // {
  // //   path: publicPath + "tokyo/s/paddock/",
  // //   name: "Signage3",
  // //   component: Signage3,
  // //   meta: {
  // //     layout: "detail",
  // //   },
  // // },
  {
    path: "*",
    redirect: publicPath + "tokyo/",
  },
];

// routesを生成する処理
function generateRoutes() {
  let processed_routes = [];

  for (let i = 0; i < routes.length; i++) {
    let route = routes[i];
    // ルートに追加
    processed_routes.push(route);
  }

  return processed_routes;
}

// eslint-disable-next-line no-unused-vars
const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    const selector = location.hash;
    if (selector) {
      // if (
      //   (from.path === "/tokyo/umajo-turfy/" &&
      //     to.path === "/tokyo/forecast/") ||
      //   (from.path === "/tokyo/shops/" && to.path === "/tokyo/forecast/")
      // ) {
      //   return { selector: selector, offset: { x: 0, y: 112 } };
      // }
      return selector;
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: generateRoutes(),
});
export default router;
