import { render, staticRenderFns } from "./SignageLayout.vue?vue&type=template&id=6a483d80"
import script from "./SignageLayout.vue?vue&type=script&lang=js"
export * from "./SignageLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/y-tamura/works/project/jra-map/publish_jra_tokyo_2024/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a483d80')) {
      api.createRecord('6a483d80', component.options)
    } else {
      api.reload('6a483d80', component.options)
    }
    module.hot.accept("./SignageLayout.vue?vue&type=template&id=6a483d80", function () {
      api.rerender('6a483d80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/SignageLayout.vue"
export default component.exports