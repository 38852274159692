<template>
  <div id="app" class="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
import { setContent } from "viewport-extra";
import DefaultLayout from "./layout/Default.vue";
import DetailLayout from "./layout/SignageLayout.vue";
import AdminLayout from "./layout/AdminLayout.vue";

export default {
  components: {
    DetailLayout,
    DefaultLayout,
    AdminLayout,
  },
  computed: {
    layout() {
      let layout = this.$route.meta.layout
        ? this.$route.meta.layout + "-layout"
        : "default-layout";
      return layout;
    },
  },
  metaInfo() {
    const signageFlag = this.$route.meta.layout === "detail" ? true : false;
    const title = "東京競馬場フジビュースタンド/メモリアル混雑マップ";
    const description =
      "東京競馬場フジビュースタンドの1・2階フロア、飲食店の混雑状況をお届けしています。";
    const link = [
      {
        rel: "apple-touch-icon",
        href: process.env.VUE_APP_PUBLIC_PATH + "img/apple-touch-icon.png",
        vmid: "apple-touch-icon",
      },
    ];
    const metaBase = [
      {
        name: "robots",
        content: "noindex, nofollow",
        vmid: "robots",
      },
      {
        name: "description",
        content: description,
        vmid: "description",
      },
    ];
    const metaSignageBase = [
      {
        name: "robots",
        content: "noindex, nofollow",
        vmid: "robots",
      },
      {
        name: "description",
        content: description,
        vmid: "description",
      },
      {
        name: "mobile-web-app-capable",
        content: "yes",
        vmid: "mobile-web-app-capable",
      },
      {
        "http-equiv": "refresh",
        content: 900,
        vmid: "refresh",
      },
    ];
    const meta = [...metaBase];
    const metaSignage = [...metaSignageBase];
    return {
      titleTemplate: title,
      link: link,
      meta: signageFlag ? metaSignage : meta,
    };
  },
  mounted() {
    setContent({ minWidth: 375 });
  },
};
</script>

<style lang="scss" scoped>
html {
  // scroll-padding-top: 53px;
}
</style>
