var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container--tmp" }, [
      _c("p", { staticClass: "content__text--tmp is-red is-center" }, [
        _vm._v(" 本実証実験は"),
        _c("br"),
        _vm._v("6月23日（日）をもって"),
        _c("br"),
        _vm._v("終了しております "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }