<template>
  <div class="default-layout">
    <tmp-text />
  </div>
</template>

<script>
import TmpText from "../components/common/TmpText.vue";

export default {
  components: {
    TmpText,
  },
};
</script>
